<template>
  <filtro-rapido v-model="abrirFiltro"
                 ref="filtrosRapidos"
                 :campos-formulario="camposFormulario"
                 :metadados="getAcaoMetadado"
                 :ordenacao-campos="ordenacaoCampos"
                 :entidade-workspace="entidadeWorkspace"
                 :aplicar-filtro-no-carregamento="aplicarFiltroNoCarregamento"
                 @FiltroRapido__AplicaFiltros="aplicarFiltros"></filtro-rapido>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FiltroRapido from '../../shared-components/filtro-rapido/FiltroRapido';
import { buscarProdutosSelecao } from '../../common/resources/produto';
import { buscarHoldings } from '../../common/resources/planejamento/planejamento-acao-cadastro';
import { buscarAcaoProjetoSelecao } from '../../common/resources/acao-projeto';

export default {
  name: 'ApuracaoAcaoFiltro',
  props: {
    value: false,
    aplicarFiltroNoCarregamento: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FiltroRapido,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      configuracaoResource: this.$api.apuracaoConfiguracao(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      anoFiscalResource: this.$api.anoFiscal(this.$resource),

      abrirFiltro: false,
      metadadosPlanejamentoAcao: null,
      ordenacaoCampos: null,
      entidadeWorkspace: 'apuracao_acao',

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'tipo_de_verba',
            nomCampoId: 'id_tipo_verba',
            tipoCampo: 'LISTA_CHECKBOX',
            async: {
              fetchFunction: (autocomplete) => this.orcamentoTipoVerbaResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'periodo',
            nomCampoId: 'ids_periodo',
            tipoCampo: 'LISTA_CHECKBOX',
            async: {
              fetchFunction: () => this.anoFiscalResource.buscarPeriodosPorTipo({ tipoPeriodo: 'PLANEJAMENTO_ORCAMENTARIO' }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'LISTA_CHECKBOX',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$t('status_entidade.apuracao_previa'),
                  valor: 'APURACAO_PREVIA',
                },
                {
                  texto: this.$t('status_entidade.aguardando_apuracao'),
                  valor: 'AGUARDANDO_APURACAO',
                },
                {
                  texto: this.$t('status_entidade.aguardando_aprovacao'),
                  valor: 'AGUARDANDO_APROVACAO',
                },
                {
                  texto: this.$tc('status_entidade.aprovado', 1),
                  valor: 'APROVADO',
                },
                {
                  texto: this.$t('status_entidade.cancelado'),
                  valor: 'CANCELADO',
                },
                {
                  texto: this.$tc('status_entidade.em_analise', 1),
                  valor: 'EM_ANALISE',
                },
                {
                  texto: this.$tc('status_entidade.reprovado', 1),
                  valor: 'REPROVADO',
                },
              ],
            },
          },
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'cod_acao',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'minhas_aprovacoes_pendentes',
            tipoCampo: 'BOOLEAN',
          },
          {
            labelCampo: 'unidadenegocio',
            nomCampoId: 'id_unidadenegocio',
            tipoCampo: 'LISTA_CHECKBOX',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarUnidadesNegocioPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'produto',
            nomCampoId: 'id_produto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  // Filtro utilizado para filtrar a combo de Produtos por UN do usuário logado
                  filtrarPorExtensao: true,
                  ...this.$refs.filtrosRapidos.getValoresDependencias(),
                };
                return buscarProdutosSelecao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codigoNome',
            },
          },
          {
            labelCampo: 'holding',
            nomCampoId: 'id_holding',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.filtrosRapidos.getValoresDependencias(),
                };
                return buscarHoldings(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarDivisoesPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'projeto',
            nomCampoId: 'id_acao_projeto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                };
                return buscarAcaoProjetoSelecao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nomProjeto',
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
    ]),
    aplicarFiltros(filtrosAplicados) {
      if (!filtrosAplicados || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      if (this.aplicarFiltroNoCarregamento) {
        this.$emit('ApuracaoAcaoFiltro__filtroCarregado');
      }
      this.$emit('ApuracaoAcaoFiltro__AplicaFiltros', filtrosAplicados);
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados && (filtrosAplicados.data_inicio || filtrosAplicados.data_fim)) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
    carregaCamposFiltros() {
      this.configuracaoResource
        .buscarCamposFiltro({ tipo: 'ACAO' })
        .then((res) => {
          this.ordenacaoCampos = res.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    this.carregaCamposFiltros();
  },
  beforeMount() {
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
  },
};
</script>
